/* c8 ignore start */ // Coverage can be improved

import { AbstractCompositionRoot } from "brainsupporter-core/lib/domain/AbstractCompositionRoot";
import { IBotCommand } from "brainsupporter-core/lib/domain/bot/IBotCommand";
import { Component, ReactNode } from "react";
import "./SmartButton.css";

type SmartButtonProps = {
  root: AbstractCompositionRoot;
  command: string;
};
type SmartButtonState = {
  buttonText: string;
};

class SmartButton extends Component<SmartButtonProps, SmartButtonState> {
  constructor(props: SmartButtonProps) {
    super(props);

    this.state = {
      buttonText: "Add task",
    };
  }

  override componentDidUpdate(prevProps: Readonly<SmartButtonProps>): void {
    if (prevProps !== this.props) {
      const botService = this.props.root.BotService;
      const botEventModel = botService.createBotEventModel(this.props.command);
      let command: IBotCommand | undefined = botService.getBotCommand(
        botEventModel.command,
      );
      if (command === undefined) {
        command = this.props.root.AddBotCommand;
      }

      this.setState({
        buttonText: command.smartButtonText,
      });
    }
  }

  override render(): ReactNode {
    return <button className="smartButton">{this.state.buttonText}</button>;
  }
}

export default SmartButton;
/* c8 ignore stop */
