// TODO: Check styling on really long contexts
import "./ContextChooser.css";

import { AbstractCompositionRoot } from "brainsupporter-core/lib/domain/AbstractCompositionRoot";
import { BotService } from "brainsupporter-core/lib/domain/bot/BotService";
import { Notifier } from "brainsupporter-core/lib/domain/pubsub/Notifier";
import { Topics } from "brainsupporter-core/lib/domain/pubsub/Topics";
import { AutoContextService } from "brainsupporter-core/lib/domain/service/AutoContextService";
import { ContextService } from "brainsupporter-core/lib/domain/service/ContextService";
import { Component } from "react";
import {
  ActionMeta,
  components,
  GroupBase,
  OptionProps,
  SingleValue,
  StylesConfig,
} from "react-select";
import CreatableSelect from "react-select/creatable";

class ContextChooserState {
  currentContext = "";
  inputContext = "";
  contextList: ContextOption[] = [];
  selectedOption: ContextOption | null = null;
}

type ContextChooserProps = {
  root: AbstractCompositionRoot;
};

interface ContextOption {
  value: string;
  label: string;
  isSeparator?: boolean;
}

const customStyles: StylesConfig<ContextOption, false> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#f2f2f2",
  }),
};

/* c8 ignore start */
const CustomOption: React.FC<
  OptionProps<ContextOption, false, GroupBase<ContextOption>>
> = (props) => {
  if (props.data.isSeparator) {
    return (
      <div
        style={{ borderTop: "1px solid #ccc", margin: "4px 0" }}
        ref={props.innerRef}
        {...props.innerProps}
      />
    );
  }
  return <components.Option {...props} />;
};
/* c8 ignore stop */

class ContextChooser extends Component<
  ContextChooserProps,
  ContextChooserState
> {
  private static UNSUBSCRIBE_GROUP = "ContextChooser";

  botService: BotService;
  contextService: ContextService;
  autoContextService: AutoContextService;
  notifier: Notifier;

  constructor(props: ContextChooserProps) {
    super(props);

    this.botService = this.props.root.BotService;
    this.contextService = this.props.root.ContextService;
    this.autoContextService = this.props.root.AutoContextService;
    this.notifier = this.props.root.Notifier;

    this.state = new ContextChooserState();
  }

  override async componentDidMount() {
    this.notifier.subscribe(
      Topics.UPDATE_UI,
      async () => {
        await this.updateContextUI();
      },
      ContextChooser.UNSUBSCRIBE_GROUP,
    );

    await this.updateContextUI();
  }

  override async componentWillUnmount() {
    this.notifier.unsubscribeGroup(ContextChooser.UNSUBSCRIBE_GROUP);
  }

  private async refreshContextList() {
    const activeContexts =
      await this.contextService.getContextsFromActiveTasks();
    let contextList: ContextOption[] = [
      {
        value: "",
        label: "Clear context",
      },
    ]; // Add empty option to clear context

    contextList = contextList.concat(
      ContextService.addAtSign(activeContexts).map((option) => {
        return { value: option, label: option };
      }),
    );

    const recentCompletedContexts =
      await this.contextService.getRecentCompletedContexts();

    /* c8 ignore start */ // TODO: Fix commented out test
    if (recentCompletedContexts.length > 0) {
      contextList = contextList.concat({
        value: "",
        label: "Recently completed",
        isSeparator: true,
      }); // Add separator for recent context (or clear context)

      contextList = contextList.concat(
        ContextService.addAtSign(recentCompletedContexts).map((option) => {
          return { value: option, label: option };
        }),
      );
    }
    /* c8 ignore stop */

    this.setState({ contextList: contextList });
  }

  private async updateContextUI() {
    await this.refreshContextList();
    const autoContext = await this.autoContextService.getContext();
    const context = ContextService.normalizeContext(autoContext);
    /* c8 ignore start */
    this.setState({
      currentContext: context,
      selectedOption: context
        ? { value: "@" + context, label: "@" + context }
        : null,
    });
    /* c8 ignore stop */
  }

  /* c8 ignore start */
  private async setContextCommand(context: string) {
    await this.botService.sendText("sc " + context);
    // Updates the UI
    await this.notifier.publish(Topics.UPDATE_UI);
    await this.notifier.publish(Topics.CLEAR_FEEDBACK);
    this.setState({
      selectedOption: context ? { value: context, label: context } : null,
    });
  }

  private setSelectedOption = async (
    newValue: SingleValue<ContextOption>,
    actionMeta: ActionMeta<ContextOption>,
  ) => {
    if (
      (actionMeta.action === "select-option" ||
        actionMeta.action === "create-option") &&
      newValue
    ) {
      await this.setContextCommand(newValue.value);
    } else if (actionMeta.action === "clear") {
      await this.setContextCommand("");
    }
  };

  private formatCreateLabel = (inputValue: string) => {
    return `Create context @${inputValue}`;
  };
  /* c8 ignore stop */

  override render() {
    return (
      <form className="contextChooserForm" data-testid="contextList">
        <label htmlFor="context">Context</label>
        <CreatableSelect
          styles={customStyles}
          options={this.state.contextList}
          onChange={this.setSelectedOption}
          value={this.state.selectedOption}
          isClearable={true}
          formatCreateLabel={this.formatCreateLabel}
          className="contextChooser"
          components={{ Option: CustomOption }}
        />
      </form>
    );
  }
}

export default ContextChooser;
