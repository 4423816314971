/* c8 ignore start */ // Coverage can be improved

import App from "./App";
import "./index.css";

import { createRoot } from "react-dom/client";

// TODO: This causes weird issues, like refreshing instead of navigating to releasenotes. Can we make it work and do we even want to use automatic refresh?
// import { registerSW } from "virtual:pwa-register";
// registerSW({ immediate: true });

const container = document.getElementById("root");

const root = createRoot(container!);

// TODO: Transform Mount and umount to useEffects? Do we lose typescript classes?
// https://www.codemzy.com/blog/how-to-use-react-useeffect-like-componentdidmount
// https://www.robinwieruch.de/react-function-component/#react-function-component-example
//
// Also read before beginning:
// TODO: Use AbortController for strictmode to properly fix it https://medium.com/doctolib/react-stop-checking-if-your-component-is-mounted-3bb2568a4934

root.render(
  //<React.StrictMode>
  <App />,
  //</React.StrictMode>,
);
/* c8 ignore stop */
