/* c8 ignore start */ // Coverage can be improved
export class BrowserFileUtil {
  public static downloadFile(data: Buffer, name: string) {
    const binaryBlobParts = [];
    binaryBlobParts.push(data);
    const url = window.URL.createObjectURL(
      new Blob(binaryBlobParts, { type: "application/zip" }),
    );
    const link = document.createElement("a");
    link.setAttribute("download", name);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }

  public static uploadFile(
    onloadFunction: (this: FileReader, ev: ProgressEvent<FileReader>) => void,
    accept: string,
  ) {
    const uploadInputElement = document.createElement("input");
    uploadInputElement.type = "file";
    uploadInputElement.accept = accept; // This doesn't work on android, although the implementation seems correct

    uploadInputElement.onchange = (e) => {
      if (!(e.target instanceof HTMLInputElement)) {
        throw new Error("Event target is not an HTMLInputElement");
      }

      const htmlInputElement = e.target as HTMLInputElement;
      const files = htmlInputElement?.files;

      if (!files) {
        throw new Error("Files empty in uploadFile");
      }

      const file = files[0];
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      // onload is fired when file is fully uploaded successfully
      reader.onload = onloadFunction;
    };

    uploadInputElement.click();
    uploadInputElement.remove();
  }
}
/* c8 ignore stop */
