import Dexie, { type EntityTable } from "dexie";

import { ICacheRepository } from "brainsupporter-core/lib//domain/ICacheRepository.js";
import { timestamp } from "brainsupporter-core/lib/domain/Types.js";
import { IIdentifiable } from "brainsupporter-core/lib/domain/domainModels/IIdentifiable.js";
import { IValidate } from "brainsupporter-core/lib/domain/domainModels/IValidate.js";
import { AbstractRepository } from "brainsupporter-core/lib/repository/AbstractRepository.js";
import { DateTimeUtils } from "brainsupporter-core/lib/util/DateTimeUtils.js";
import { DependencyInjectionUtils } from "brainsupporter-core/lib/util/DependencyInjectionUtils.js";
import { ModelCloner } from "brainsupporter-core/lib/util/ModelCloner.js";

// TODO: Solve clearing the index db on changing user
/* c8 ignore start */ // TODO: Write tests
export class IndexedDbRepository<T extends IIdentifiable & IValidate>
  extends AbstractRepository<T>
  implements ICacheRepository<T>
{
  private db!: Dexie;

  constructor(private readonly databaseName: string) {
    super();

    DependencyInjectionUtils.validateDependenciesDefined(arguments);

    this.initializeDB();
    // this.table.mapToClass(T); // TODO: Need this?
  }

  private initializeDB() {
    this.db = new Dexie(this.databaseName) as Dexie & {
      repoTable: EntityTable<T, "uuid">;
      timestampTable: EntityTable<TimestampModel, "id">;
    };

    // Can we ignore it for now by deleting it all and rebuilding?
    // Schema declaration:
    this.db.version(1).stores({
      repoTable: "uuid",
      timestampTable: "id",
    });
  }

  public async getAll(): Promise<readonly T[]> {
    const storedModels = await this.db.table("repoTable").toArray();

    const clonedModels: T[] = [];
    for (const storedModel of storedModels) {
      // TODO: Measure perf. Better to use copyToType copyToTypeNew or CloneToType?
      const model = ModelCloner.cloneToType(storedModel) as T;
      model.validate();
      clonedModels.push(model);
    }

    return Promise.resolve(clonedModels);
  }

  public override async tryGetByUuid(uuid: string): Promise<T | undefined> {
    const model: T | undefined = await this.db.table("repoTable").get(uuid);
    if (model) {
      return ModelCloner.cloneToType(model) as T;
    } else {
      return undefined;
    }
  }

  public async save(model: T, timestamp?: timestamp): Promise<timestamp> {
    await this.db.table("repoTable").put(model);
    return this.writeTimestamp(timestamp);
  }

  public async deleteAll(): Promise<timestamp> {
    await this.db.table("repoTable").clear();
    await this.db.table("timestampTable").clear();
    return DateTimeUtils.zeroTimestamp;
  }

  public async getTimestamp(): Promise<timestamp> {
    const timestamp = (await this.db
      .table("timestampTable")
      .get(fixedTimestampID)) as TimestampModel | undefined;

    if (timestamp) {
      return timestamp.timestamp;
    } else {
      return DateTimeUtils.zeroTimestamp;
    }
  }

  public async delete(uuid: string, timestamp?: timestamp): Promise<timestamp> {
    await this.db.table("repoTable").delete(uuid);
    return this.writeTimestamp(timestamp);
  }

  public async import(
    models: readonly T[],
    timestamp?: timestamp,
  ): Promise<timestamp> {
    await this.deleteAll();
    await this.db.table("repoTable").bulkPut(models);
    return this.writeTimestamp(timestamp);
  }

  private async writeTimestamp(timestamp?: timestamp): Promise<timestamp> {
    timestamp ??= DateTimeUtils.zeroTimestamp;
    await this.db
      .table("timestampTable")
      .put(new TimestampModel(timestamp), fixedTimestampID);
    return timestamp;
  }
}

const fixedTimestampID = 1;
// TODO: Write tests
class TimestampModel {
  public id = fixedTimestampID; // Fixed ID as we only want to store 1 row
  public timestamp: timestamp;

  constructor(timestamp: timestamp) {
    this.timestamp = timestamp;
  }
}
/* c8 ignore stop */
