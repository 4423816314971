/* c8 ignore start */ // Coverage can be improved

import isMobile from "is-mobile";
import { Component } from "react";

import "./MobileKeys.css";

// TODO: Build slider to manually enable mobile functions?

type MobileKeysProps = {
  simulateTab: () => Promise<void>;
};

class MobileKeys extends Component<MobileKeysProps> {
  constructor(props: MobileKeysProps) {
    super(props);
  }

  override render() {
    if (isMobile({ tablet: true })) {
      return (
        <div className="keysRow">
          <label>Mobile keys: </label>
          <button
            type="button"
            className="mobile-key"
            onClick={this.props.simulateTab}
          >
            tab
          </button>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default MobileKeys;
/* c8 ignore stop */
